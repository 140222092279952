.noti-icon .badge {
  left: 23px;
}

.mt-2 {
  margin-top: 20px !important;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.dz-message {
  text-align: center;
  padding: 100px;
}

.fcYNFc {
  background-color: #ffffff !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

.custom-btn{
  border: none;
  background: inherit;
}

.dot {
  height: 6px;
  width: 6px;
  background-color: rgba(0,0,0,0.1);
  border-radius: 50%;
  &.active{
    background-color: #7cb342;
  }
}

.lang-tab-select-container {
  width: fit-content;
  border: rgba(0,0,0,0.2) solid 1px;
  border-radius: 10rem;
  padding: 5px 10px;
  button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 0 10px;
    text-wrap: nowrap;
    &:not(:last-of-type){
      border-right: rgba(0,0,0,0.2) solid 1px;
    }
  }
}

.form-group {
  input:disabled {
    cursor: unset;
  }

  .select2-selection--is-disabled {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    cursor: not-allowed;

    div {
      cursor: 'not-allowed';
      background-color: #eff2f7;

      .select2-selection__single-value {
        color: #495057;
      }
    }
    .select2-selection__multi-value__label {
      background-color: #e2dcdc;
    }

    .select2-selection__multi-value__remove {
      background-color: #e2dcdc;
    }
  }
}

.case-category-breadcrumb-container {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;


  .placeholder-container {
    width: 200px;
    height: 61px;
  }

  .arrow-right {
    margin: 0px 20px;
  }

  .breadcrumb-item-container {
    width: 200px;
    // height: 62px;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0px -3px 18px rgba(171, 171, 171, 0.25);

    .name-text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: calc(200px - 60px);
    }
  }
}

.sortable-table-row {

  td:first-child {
    padding-left: 20px;
  }
  .sortable-indicator {
    position: absolute;
    left: 0px;
    height: 98%;
    margin: 0px 0px;
    width: 10px;
    background-color: #4444 !important;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
